
/**********************************
    Add class to body when scroll
**********************************/
var prevScrollpos = window.pageYOffset
// var body = document.querySelector('#page');
var body = document.querySelector('body');
var gap = 0;
var navbarScrollTrigger = 150;
const submenus = document.querySelectorAll(".dropdown-menu");

window.addEventListener('scroll', function () {
    var currentScrollPos = window.pageYOffset

    if (window.pageYOffset > navbarScrollTrigger) {
        body.classList.add('window-scroll');

    }else{
        body.classList.remove('window-scroll');
    }

    if ( gap > 20 && body.classList.contains('window-scroll') )  {
        body.classList.add('navbar-deploy');
    }else{
        body.classList.remove('navbar-deploy');
        body.classList.remove('submenu-open');
        submenus.forEach(function(menu) {
            menu.classList.remove('show');
        });
    }

    if ( prevScrollpos > currentScrollPos ){
        gap = gap + 1;
    }else{
        gap = 0;
    }
    prevScrollpos = currentScrollPos
})



